import bg1 from "../assets/aboutImg1.jpeg"
import bg2 from "../assets/aboutImg2.jpeg"
import bg3 from "../assets/aboutImg3.jpeg"
import bg4 from "../assets/aboutImg4.jpeg"
import bg5 from "../assets/aboutImg5.jpeg"


export const pictures = [
    {img: bg1},
    {img: bg2},
    {img: bg3},
    {img: bg4},
    {img: bg5},
    
]